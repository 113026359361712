const getValueForInputEnergieElectrica = value => {
   const valueMap = {
      25: "75",
      40: "90",
      60: "125",
      90: "150",
      100: "200",
      130: "250",
      200: "300",
   };
   return valueMap[value] || "75";
};
const getValueForInputGaz = value => {
   const valueMap = {
      25: "250",
      40: "400",
      60: "600",
      90: "800",
      100: "1000",
      130: "1200",
      200: "1300",
   };
   return valueMap[value] || "250";
};

const getVoltageToPost = voltage => {
   switch (voltage) {
      case "JOASA_TENSIUNE":
         return "low";
      case "MEDIE_TENSIUNE":
         return "medium";
      case "INALTA_TENSIUNE":
         return "high";
      default:
         return "low";
   }
};

// const showCorrectPrice = (price, denominare, isInMWh = false) => {
//    console.log("🚀 ~ showCorrectPrice ~ isInMWh:", isInMWh);
//    console.log("🚀 ~ showCorrectPrice ~ denominare:", denominare);
//    console.log("🚀 ~ showCorrectPrice ~ price:", price);
//    if (denominare === "MWh" && !isInMWh) {
//       const result = (price * 1000).toFixed(5);
//       return parseFloat(result).toString();
//       // return result;
//    } else if (denominare === "kWh" && isInMWh) {
//       const result = (price / 1000).toFixed(5);

//       return parseFloat(result).toString();
//    } else return parseFloat(price).toString();
// };
const showCorrectPriceV1 = (price, denominare) => {
   if (denominare === "MWh") {
      const result = (price * 1000).toFixed(5);
      return parseFloat(result).toString();
   } else return parseFloat(price).toString();
};
const showCorrectPrice = (price, denominare,isPersoanaJuridica) => {

   if(denominare === "MWh" && isPersoanaJuridica){
     return  parseFloat(price).toString();
   }

   if(denominare === "kWh" && isPersoanaJuridica){
      const result = (price / 1000).toFixed(5);
      return parseFloat(result).toString();
   }

   if(denominare === "MWh" && !isPersoanaJuridica){
      const result = (price * 1000).toFixed(5);
      return parseFloat(result).toString();
   }

   if(denominare === "kWh" && !isPersoanaJuridica){
      return  parseFloat(price).toString();
   }

};
const showCardTitle = (isDetaliiShowing, typeOfOffer) => {
   const typeOfOfferText = typeOfOffer === "GAZ" ? "gaze naturale" : "energie electrică";
   if (isDetaliiShowing) return `Inapoi la oferta de ${typeOfOfferText}`;
   else return `Ofertă ${typeOfOfferText}`;
};

const getValueForConsumptionAgreement = estimated => {
   return {
      january_kwh: estimated,
      february_kwh: estimated,
      march_kwh: estimated,
      april_kwh: estimated,
      may_kwh: estimated,
      june_kwh: estimated,
      july_kwh: estimated,
      august_kwh: estimated,
      september_kwh: estimated,
      october_kwh: estimated,
      november_kwh: estimated,
      december_kwh: estimated,
   };
};

const getInitialSelectedUtility = utility_type => {
   switch (utility_type) {
      case "GAZ":
         return "GAZ";
      case "CURENT":
         return "CURENT";
      case "GAZ_SI_CURENT":
         return "GAZ";
      default:
         return "GAZ";
   }
};

const getInitialPdfTitle = (formikValues, objKeysGas, objKeysEnergy) => {
   const initialPdf = formikValues?.gas ? "gas" : "energy";

   if (initialPdf === "gas") return objKeysGas[0];
   else return objKeysEnergy[0];
};

const valueForConventieInput = (formikValues, month, isInArray, arrayIndex, utility_type, typeOfOperation) => {
   const operations = {
      "/": value => value / 1000,
      "*": value => value * 1000,
   };
   const operationFunction = operations[typeOfOperation];
   const utilityTypeKey = utility_type === "ELECTRICITATE" ? "consumption_agreement_energy" : "consumption_agreement_gas";

   let value;

   if (isInArray) value = formikValues.locations?.[arrayIndex]?.[utilityTypeKey]?.[month];
   else value = formikValues[utilityTypeKey][month];

   const result = operationFunction(value);

   return String(result);
};

export const functionHelpers = {
   getValueForInputEnergieElectrica,
   getValueForInputGaz,
   getVoltageToPost,
   showCorrectPrice,
   showCardTitle,
   getValueForConsumptionAgreement,
   getInitialSelectedUtility,
   getInitialPdfTitle,
   valueForConventieInput,
   showCorrectPriceV1
};
