import React, { useState } from "react";
import { functionHelpers } from "helpers/functionHelpers";

export default function CardOferta({ oferta, denominare, isWithSelect, isCardSelected, onSelect, typeOfOffer, isPersoanaJuridica, tenant_assoc }) {
   const [isDetaliiShowing, setIsDetaliiShowing] = useState(false);
   const plafoane = isPersoanaJuridica ? [1, 2] : [1, 2, 3];
   return (
      <div
         className={`oferta-card-container ${
            isCardSelected && typeOfOffer === "GAZ" ? "background-blue" : isCardSelected && typeOfOffer === "ELECTRICITATE" ? "background-orange" : ""
         }  `}
      >
         <div className="oferta-card-title">
            <h3
               onClick={() => setIsDetaliiShowing(false)}
               className={`${typeOfOffer === "ELECTRICITATE" ? "text-orange" : "text-blue"}
               ${isDetaliiShowing ? "card-detalii-title" : ""} ${isCardSelected ? "oferta-card-selected" : ""}`}
            >
               {functionHelpers.showCardTitle(isDetaliiShowing, typeOfOffer)}
            </h3>
            <div className={`${typeOfOffer === "ELECTRICITATE" ? "servicii-curent-img" : "servicii-gaz-img"}`} />
         </div>
         {!isDetaliiShowing ? (
            <div className={`${isCardSelected ? "oferta-card-selected" : ""}`}>
               <p className="oferta-card-pret_furnizare">
                  Preț {typeOfOffer === "ELECTRICITATE" ? "energie electrică" : "gaze naturale"}:{" "}
                  <span className="text-red text-bold">{functionHelpers.showCorrectPrice(oferta.supply_price, denominare, isPersoanaJuridica)}</span>{" "}
                  lei + TVA/
                  {denominare}
               </p>
               {/*<p className="oferta-card-pret_total">*/}
               {/*    Preț*/}
               {/*    total/{denominare}: {functionHelpers.showCorrectPrice(oferta.distribution_price_total, denominare)} lei*/}
               {/*</p>*/}
               <p className="oferta-card-valoare-estimata">
                  Valoarea estimată a facturii <br />
                  <span className="text-red">{Number(oferta.price.toFixed(2))} lei</span>
               </p>
               <p onClick={() => setIsDetaliiShowing(true)} className="oferta-card-detalii">
                  Mai multe detalii
               </p>

               {typeOfOffer === "ELECTRICITATE" && (
                  <>
                     <div style={{ textAlign: "justify" }}>
                        <p className="box-price">
                           Consumul de energie electrică lunar estimat vă incadrează in
                           <span className="red-bold"> Plafonul {oferta.FittingPlafon} </span>
                           de compensare.
                        </p>
                     </div>
                     <>
                        {oferta.FittingPlafon && (
                           <>
                              {plafoane
                                 .filter(plafon => plafon !== oferta.FittingPlafon)
                                 .map(plafon => (
                                    <p key={plafon + "plafon"} className="box-price">
                                       Preț EE Plafon {plafon}:
                                       <span className="red-bold">
                                          <span> {functionHelpers.showCorrectPrice(oferta[`PretFurnizarePlafon${plafon}`], denominare, isPersoanaJuridica)}</span> lei
                                       </span>{" "}
                                       + TVA/<span> {denominare}</span>
                                    </p>
                                 ))}
                           </>
                        )}
                     </>
                  </>
               )}
               {isWithSelect && (
                  <div
                     onClick={() => onSelect(oferta)}
                     className={`oferta-card-select-container ${isCardSelected ? "oferta-card-select-container-selected" : ""}`}
                  >
                     <p>{isCardSelected ? "Pachet ales" : "Alege pachet"}</p>
                  </div>
               )}
            </div>
         ) : (
            <div className={`${isCardSelected ? "oferta-card-selected" : ""}`}>
               <p className="oferta-card-componente-title">Componente preț (lei/{denominare})</p>
               <p className="oferta-card-componente-pret">
                  Preț
                  {typeOfOffer === "ELECTRICITATE" ? " energie electrică" : " gaze naturale"}:{" "}
                  {functionHelpers.showCorrectPrice(oferta.supply_price, denominare, isPersoanaJuridica)} lei
               </p>
               {/* {!isPersoanaJuridica && (
                  <p className="oferta-card-componente-pret">Tarif zilnic: {functionHelpers.showCorrectPrice(oferta.daily_price, denominare)} lei</p>
               )} */}
               {typeOfOffer === "ELECTRICITATE" && (
                  <p className="oferta-card-componente-pret">Tarif TL: {functionHelpers.showCorrectPriceV1(oferta.tl_price, denominare, isPersoanaJuridica)} lei</p>
               )}
               {typeOfOffer === "ELECTRICITATE" && (
                  <p className="oferta-card-componente-pret">Tarif TG: {functionHelpers.showCorrectPriceV1(oferta.tg_price, denominare,isPersoanaJuridica)} lei</p>
               )}
               {typeOfOffer === "ELECTRICITATE" && (
                  <p className="oferta-card-componente-pret">Tarif sistem: {functionHelpers.showCorrectPriceV1(oferta.system_price, denominare,isPersoanaJuridica)} lei</p>
               )}
               {typeOfOffer === "ELECTRICITATE" && (
                  <p className="oferta-card-componente-pret">
                     Tarif cogenerare: {functionHelpers.showCorrectPriceV1(oferta.cogeneration_price, denominare,isPersoanaJuridica)} lei
                  </p>
               )}
               {typeOfOffer === "ELECTRICITATE" && (
                  <p className="oferta-card-componente-pret">
                     Tarif CfD: {functionHelpers.showCorrectPriceV1(oferta.Cfd, denominare,isPersoanaJuridica)} lei
                  </p>
               )}
               <p className="oferta-card-componente-pret">
                  Tarif distributie: {functionHelpers.showCorrectPriceV1(oferta.distribution_price, denominare,isPersoanaJuridica)} lei
               </p>
               {typeOfOffer === "ELECTRICITATE" && (
                  <p className="oferta-card-componente-pret">
                     Certificate verzi: {functionHelpers.showCorrectPriceV1(oferta.green_certificates, denominare,isPersoanaJuridica)} lei
                  </p>
               )}
               {typeOfOffer === "GAZ" && (
                  <p className="oferta-card-componente-pret">
                     Tarif transport: {functionHelpers.showCorrectPriceV1(oferta.transport_price, denominare,isPersoanaJuridica)} lei
                  </p>
               )}

               {typeOfOffer === "ELECTRICITATE" ||
                  (isPersoanaJuridica && (
                     <p className="oferta-card-componente-pret">Accize: {functionHelpers.showCorrectPriceV1(oferta.excise_duty, denominare,isPersoanaJuridica)} lei</p>
                  ))}

               <p className="oferta-card-componente-pret">TVA: {functionHelpers.showCorrectPrice(oferta.vat, denominare,isPersoanaJuridica)} lei</p>
            </div>
         )}
      </div>
   );
}
